import React from 'react';
import { Box } from '@mui/material';
import { Button, Card, Icon, Typography } from '@tucar/pit-stop';
// import { useSalesforceContext } from '../../../../contexts/SalesforceContext';

const ExtraInfoCard = () => {
  // const { openSalesforceChat } = useSalesforceContext();
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '14px',
        marginTop: '14px',
      }}
    >
      <Card
        style={{
          height: 'fit-content',
          padding: '16px 12px',
          gap: '12px',
          justifyContent: 'center',
        }}
      >
        <Icon name="supportAgentOutlined" color="#1F89FF" size="24px" />
        <Typography
          style={{ color: '#1F89FF', fontSize: '14px', fontWeight: 500, lineHeight: 1.2 }}
        >
          ¿Tienes dudas?
        </Typography>
        {/* <Button size="small" onClick={openSalesforceChat}> */}
        <Button size="small" onClick={() => window.Intercom('show')}>
          Contactar
        </Button>
      </Card>

      <Card style={{ padding: '16px 12px', gap: '12px', justifyContent: 'center' }}>
        <Icon name="securityOutlined" color="#7FB418" size="24px" />
        <Typography
          style={{
            color: '#7FB418',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: 1.2,
            textAlign: 'center',
          }}
        >
          ¿Consejos de seguridad?
        </Typography>
        <Typography
          style={{
            color: '#5B5D71',
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: 1.2,
            textAlign: 'center',
          }}
        >
          Prioriza tu seguridad en un robo, reporta de inmediato.
        </Typography>
        <Button
          size="small"
          onClick={() => window.open('https://tucar.com/preguntas-frecuentes')}
          contentStyle={{ lineHeight: 1.2 }}
        >
          Más información
        </Button>
      </Card>
    </Box>
  );
};

export default ExtraInfoCard;
