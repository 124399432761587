import React, { useEffect, useState } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { Box, ClickAwayListener } from '@mui/material';
import { BarGraph, Card, Icon, Loader, SideBar, Typography } from '@tucar/pit-stop';
import { useReactiveVar, useLazyQuery } from '@apollo/client';
import { getAuthStore, getDriverStore } from '../../../../graphql/store';
import { azulTucar } from '../../../../themes/variants/TucarColors';
import GET_DRIVER_BILLDAY_LIMIT from '../../../../graphql/querys/driver/getBillDays';
import NameAndTurnBox from './sections/NameAndTurn';
import ProfitsCard from './sections/ProfitsCard';
import PressIconButton from '../../../../components/buttons/PressIconButton';
import { formatData, backgroundStyle, basicSectionsSideBar, bottomSideBar } from './auxFunctions';
import PaymentDebtCard from './sections/PaymentDebtCard';
import ManageButtons from './sections/ManageButtons';
import RejectedScreen from './screens/RejectedScreen';
import SleepScreen from './screens/SleepingScreen';
import ReturnScreen from './screens/ReturnScreen';
import TopBarDashboard from './sections/TopBarDashboard';
import ShortCuts from './sections/ShortCuts';
import { getStartOfWeek, getEndOfWeek } from '../../../../helpers/dateFormat';
// import { useSalesforceContext } from '../../../../contexts/SalesforceContext';

const NewDashboard = () => {
  const navigate = useNavigate();

  const [handlePageChange] = useOutletContext();
  const [openSide, setOpenSide] = useState(false);
  const [currentData, setCurrentData] = useState({
    totalExpenses: 0,
    totalCollections: 0,
    totalGaining: 0,
    totalCash: 0,
    kmTotal: 0,
    kmUber: 0,
    uberPercentage: 0,
    kmByDay: [],
  });
  const {
    data: { uid, firstname, lastname },
  } = useReactiveVar(getAuthStore);
  const { rent, status, photo } = useReactiveVar(getDriverStore);
  // const { openSalesforceChat } = useSalesforceContext();

  const sectionsSideBar = basicSectionsSideBar(navigate);

  const [paymentData, setPaymentData] = useState({});
  const [openDoorShow, setOpenDoorShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [getBillDays, { loading, data }] = useLazyQuery(GET_DRIVER_BILLDAY_LIMIT.query, {
    ...GET_DRIVER_BILLDAY_LIMIT.policies,
    variables: {
      uid,
      initialDatetime: getStartOfWeek(selectedDate),
      finalDatetime: getEndOfWeek(selectedDate),
      labelId: 'REMOTE_CONTROL_DOORS',
      carId: rent?.carId || '',
      skipCarStatus: !rent?.carId,
    },
  });

  useEffect(() => {
    handlePageChange(false, true);
    getBillDays();
  }, []);

  useEffect(() => {
    getBillDays({
      initialDatetime: getStartOfWeek(selectedDate),
      finalDatetime: getEndOfWeek(selectedDate),
    });
  }, [selectedDate]);

  useEffect(() => {
    if (data && data?.getDriverBillDaysLimitDates) {
      const formatedData = formatData(data.getDriverBillDaysLimitDates);
      setCurrentData(formatedData);
    }
    if (data && data?.getLabel && data?.carStatus) {
      setOpenDoorShow(data.getLabel.elements.includes(rent?.carId));
    }
    if (data && data?.paymentArray && data?.paymentArray.length > 0) {
      setPaymentData(data.paymentArray[0]);
    }
  }, [loading, data]);

  if (loading) {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Loader size={32} pointRadio="4px" />
      </Box>
    );
  }

  return (
    <>
      <ReturnScreen />
      <SleepScreen />
      <RejectedScreen />
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={() => (openSide ? setOpenSide(false) : null)}
      >
        <Box>
          <SideBar
            onClickPhoto={() => navigate('/app/v2/profile', { replace: true })}
            userPhoto={photo}
            userName={`${firstname} ${lastname}`}
            sections={sectionsSideBar}
            // bottomContent={bottomSideBar({ onContactClick: openSalesforceChat })}
            bottomContent={bottomSideBar({ onContactClick: () => window.Intercom('show') })}
            open={openSide}
            variant="mobile"
          />
        </Box>
      </ClickAwayListener>

      <Box sx={backgroundStyle(status.status === 'rent')}>
        <TopBarDashboard
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
          setOpenSide={setOpenSide}
        />

        <NameAndTurnBox />

        <Box sx={{ marginTop: '35px', display: 'flex', justifyContent: 'center' }}>
          <PaymentDebtCard data={paymentData} />
        </Box>

        <ManageButtons openDoorShow={openDoorShow} />

        <ProfitsCard data={currentData} />
        {/* shortCut buttons  */}
        <ShortCuts />

        {/* km uber % card  */}
        <Card
          style={{
            padding: '12px 16px',
            marginTop: '30px',
            gap: '20px',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <PressIconButton>
            <Icon name="equalizerRounded" color="#95D31C" size="36px" />
          </PressIconButton>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography color="#5B5D71" style={{ fontSize: '15px', fontWeight: 600 }}>
              El {currentData.uberPercentage}% de tus kilómetros
            </Typography>
            <Typography color="#5B5D71" style={{ fontSize: '14px', fontWeight: 500 }}>
              Han sido en viajes de Uber
            </Typography>
          </Box>
        </Card>
        {/* km total trips  */}
        <Box
          sx={{
            marginTop: '34px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
            color={azulTucar}
            style={{
              fontSize: '24px',
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            {currentData.kmTotal
              .toFixed(0)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}{' '}
            KM
          </Typography>
        </Box>
        {/* km graph trips  */}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '40px',
          }}
        >
          {currentData.kmByDay.length > 0 && currentData.kmTotal > 0 && (
            <BarGraph
              data={currentData.kmByDay}
              step={5}
              barWidth="18px"
              gap="20px"
              total={currentData.maxKm}
              maxWidth="max-content"
              maxHeight="250px"
              finalLabelType=" km"
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default NewDashboard;
