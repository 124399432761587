import React from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import GeneralLayout from './layouts/generalLayout';
// Import Pages
import IndexPage from './pages';
import NotFound from './components/NotFound';
import SignIn from './pages/auth/signIn';
import SignInUber from './pages/auth/signInUber';
import SignUp from './pages/auth/signUp';
import SignUpUber from './pages/auth/signUpUber';
import LinkUpUber from './pages/auth/linkUpUber';
import Thanks from './pages/auth/thanks';
import OnBoarding from './pages/onBoarding';
import Dashboard from './pages/app/dashBoard';
import NewDashboard from './pages/app/dashBoard/newDashboard';
import Forgot from './pages/auth/forgot';
import Apps from './pages/app/apps';
import Events from './pages/app/events';
import Moviments from './pages/app/moviments';
import Tickets from './pages/app/tickets';
import PaymentProof from './pages/general/paymentProof';
import Guarantee from './pages/app/guarantee';
import Payment from './pages/general/payment';
import BalanceLiquidation from './pages/app/liquidation/OldVersion/detailLiquidation';
// import SalesforceApp from './components/salesforce';
import IntercomApp from './components/Intercom';
import ErrorView from './pages/general/ErrorView';
import TutorialDashboard from './pages/tutorial/dashboard';
import TutorialMenu from './pages/tutorial/menu';
import TutorialNewDashboard from './pages/tutorial/v2/dashboard';

import RentQueueStatus from './pages/general/rentQueueStatus';
import ManageShift from './pages/exchange/manageShift';
import InitForm from './pages/exchange/InitForm';
import Benefits from './pages/app/benefits';
import TutorialLiquidations from './pages/tutorial/liquidations';
import ManageShared from './pages/exchange/ManageShared';
import WaitingList from './pages/app/waitingList';
import TermsAndCondition from './pages/general/Terms&Conditions';
import Vouchers from './pages/app/vouchers';
import ShiftPetitions from './pages/app/petitions';
import CreateShiftPetition from './pages/app/petitions/createPetition';
import CarSheet from './pages/app/car/carSheet';
import Maintenance from './pages/app/car/maintenance';
import DeductibleView from './pages/app/car/deductibles';
import DocumentsCarView from './pages/app/car/carDocuments';
import CheckPetition from './pages/app/petitions/checkIfPetition';
import TagScreen from './pages/app/tags';
import UberTripScreen from './pages/app/uberTrips';
import ExchangeEvents from './pages/app/events/bySubjects/exchangeEvents';
import ControlEvents from './pages/app/events/bySubjects/controlEvents';
import ExpensesEvents from './pages/app/events/bySubjects/expensesEvents';
import IncomesEvents from './pages/app/events/bySubjects/incomesEvents';
import NewProfile from './pages/app/profile/NewVersion';
import NewNotifications from './pages/app/notifications/NewVersion';
import NewLiquidations from './pages/appV2/liquidations';
import NewSubscriptionPlan from './pages/app/subscription/NewVersion';
import Liquidations from './pages/app/liquidation/OldVersion';
import OldSubscriptionPlan from './pages/app/subscription/OldVersion';
import BalanceView from './pages/app/balance/OldVersion';
import GainingView from './pages/app/balance/NewVersion';
import OldNotifications from './pages/app/notifications/OldVersion';
import OldProfile from './pages/app/profile/OldVersion';
import CashBlock from './pages/appV2/cashBlock';
import NewShiftPetitions from './pages/appV2/petitions';
import CurrentPetition from './pages/appV2/petitions/currentPetition';
import AcceptScreen from './pages/appV2/petitions/auxScreens/acceptScreen';
import RejectScreen from './pages/appV2/petitions/auxScreens/rejectScreen';
import TutorialPetitions from './pages/tutorial/v2/petitions';
import Chargers from './pages/appV2/chargers';

function Router() {
  return (
    <Routes>
      <Route path="/linkup/uber" element={<LinkUpUber />} />
      <Route path="/terms-and-conditions" element={<TermsAndCondition />} />

      <Route path="/" element={<GuestGuard />}>
        <Route element={<GeneralLayout onBoarding />}>
          <Route index element={<IndexPage />} />
          <Route path="404" element={<NotFound />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin/uber" element={<SignInUber />} />
          <Route path="/signup/uber" element={<SignUpUber />} />
          <Route path="/thanks" element={<Thanks />} />
          <Route path="/forgot" element={<Forgot />} />
        </Route>
      </Route>

      <Route path="/app/*" element={<AuthGuard />}>
        <Route element={<IntercomApp show />}>
          {/* <Route element={<SalesforceApp />}> */}
          <Route element={<GeneralLayout onBoarding />}>
            <Route path="error" element={<ErrorView />} />
            <Route path="onboarding" element={<OnBoarding />} />
            <Route path="waitingList" element={<WaitingList />} />
          </Route>
        </Route>
        <Route element={<IntercomApp show />}>
          {/* <Route element={<SalesforceApp />}> */}
          <Route element={<GeneralLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="profile" element={<OldProfile />} />
            <Route path="notifications" element={<OldNotifications />} />
            <Route path="applications" element={<Apps />} />
            <Route path="events" element={<Events />} />
            <Route path="moviments" element={<Moviments />} />
            <Route path="liquidations" element={<Liquidations />} />
            <Route path="liquidations/balance" element={<BalanceLiquidation />} />
            <Route path="tickets" element={<Tickets />} />
            <Route path="proof" element={<PaymentProof />} />
            <Route path="petitions/check" element={<CheckPetition />} />
            <Route path="petitions" element={<ShiftPetitions />} />
            <Route path="petitions/create" element={<CreateShiftPetition />} />
            <Route path="subscription" element={<OldSubscriptionPlan />} />
            <Route path="guarantee" element={<Guarantee />} />
            <Route path="balance" element={<BalanceView />} />
            <Route path="payment" element={<Payment />} />
            <Route path="initForm" element={<InitForm />} />
            <Route path="manageshift" element={<ManageShift />} />
            <Route path="manageshare" element={<ManageShared />} />
            <Route path="benefits" element={<Benefits />} />

            <Route path="car/*">
              <Route path="carsheet" element={<CarSheet />} />
              <Route path="maintenance" element={<Maintenance />} />
              <Route path="deductibles" element={<DeductibleView />} />
              <Route path="documents" element={<DocumentsCarView />} />
            </Route>

            <Route path="tutorial/*">
              <Route path="dashboard" element={<TutorialDashboard />} />
              <Route path="menu" element={<TutorialMenu />} />
              <Route path="liquidations" element={<TutorialLiquidations />} />
            </Route>

            <Route path="v2/*">
              <Route path="petitions/*">
                <Route path="" element={<NewShiftPetitions />} />
                <Route path="current" element={<CurrentPetition />} />
                <Route path="success" element={<AcceptScreen />} />
                <Route path="reject" element={<RejectScreen />} />
              </Route>
              <Route path="cash-block" element={<CashBlock />} />
              <Route path="dashboard" element={<NewDashboard />} />
              <Route path="profile" element={<NewProfile />} />
              <Route path="notifications" element={<NewNotifications />} />
              <Route path="rent-queue-status" element={<RentQueueStatus />} />
              <Route path="vouchers" element={<Vouchers />} />
              <Route path="liquidations" element={<NewLiquidations />} />
              <Route path="subscription" element={<NewSubscriptionPlan />} />
              <Route path="balance" element={<GainingView />} />
              <Route path="tags" element={<TagScreen />} />
              <Route path="trips" element={<UberTripScreen />} />
              <Route path="exchange-events" element={<ExchangeEvents />} />
              <Route path="control-events" element={<ControlEvents />} />
              <Route path="expenses-events" element={<ExpensesEvents />} />
              <Route path="incomes-events" element={<IncomesEvents />} />
              <Route path="chargers" element={<Chargers />} />
              <Route path="tutorial/*">
                <Route path="dashboard" element={<TutorialNewDashboard />} />
                <Route path="petitions" element={<TutorialPetitions />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="404" />} />
    </Routes>
  );
}

export default Router;
