import React, { useState, useEffect } from 'react';
import { Icon } from '@tucar/pit-stop';
import { useNavigate } from 'react-router-dom';
import { BottomNavigationAction, Paper, BottomNavigation, Box } from '@mui/material';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import { azulTucar } from '../../../themes/variants/TucarColors';
import PressIconButton from '../../../components/buttons/PressIconButton';
// import { useSalesforceContext } from '../../../contexts/SalesforceContext';

function NewNavBar() {
  const navigate = useNavigate();
  const [unreadCount, setUnreadCount] = useState(0);
  const [value, setValue] = useState(0);
  const styleButtonNav = { minWidth: 'auto', paddingY: '0px' };
  const styleButtonNavSelected = { minWidth: 'auto', paddingY: '0px' };
  // const { openSalesforceChat, unreadCount } = useSalesforceContext();

  // const handleChatButtonClick = () => openSalesforceChat();

  window.Intercom('onUnreadCountChange', (currentUnreadCount) => {
    if (currentUnreadCount !== unreadCount) setUnreadCount(currentUnreadCount);
  });

  useEffect(() => {
    if (value === 1 && unreadCount === 0) {
      window.Intercom('showMessages');
      window.Intercom('hide');
    }
  }, [value]);

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: '2',
        height: '84px',
        backgroundColor: '#F0F0F3',
      }}
      elevation={5}
    >
      <BottomNavigation
        sx={{
          height: '100%',
          borderRadius: '10px 10px 0px 0px',
          boxShadow: '8px 0px 6px 2px rgba(177, 177, 177, 0.25)',
        }}
        value={value}
        onChange={(_, newValue) => setValue(newValue)}
      >
        <BottomNavigationAction
          sx={{ ...styleButtonNav, '&.Mui-selected': styleButtonNavSelected }}
          onClick={() => navigate('/app/v2/dashboard', { replace: true })}
          icon={
            value === 0 ? (
              <PressIconButton size="50px">
                <Icon name="dashboardFilled" color={azulTucar} size="30px" />
              </PressIconButton>
            ) : (
              <Icon name="dashboardFilled" color="#8E90A4" size="30px" />
            )
          }
        />

        <BottomNavigationAction
          sx={{ ...styleButtonNav, '&.Mui-selected': styleButtonNavSelected }}
          onClick={() => navigate('/app/v2/subscription', { replace: true })}
          icon={
            value === 1 ? (
              <PressIconButton size="50px">
                <Icon name="directionsCarFilled" color={azulTucar} size="30px" />
              </PressIconButton>
            ) : (
              <Icon name="directionsCarFilled" color="#8E90A4" size="30px" />
            )
          }
        />

        <BottomNavigationAction
          sx={{ ...styleButtonNav, '&.Mui-selected': styleButtonNavSelected }}
          onClick={() => navigate('/app/v2/balance', { replace: true })}
          icon={
            value === 2 ? (
              <PressIconButton size="50px">
                <Icon name="attachMoneyFilled" color={azulTucar} size="30px" />
              </PressIconButton>
            ) : (
              <Icon name="attachMoneyFilled" color="#8E90A4" size="30px" />
            )
          }
        />

        <BottomNavigationAction
          sx={{ ...styleButtonNav, '&.Mui-selected': styleButtonNavSelected }}
          onClick={() => navigate('/app/v2/liquidations', { replace: true })}
          icon={
            value === 3 ? (
              <PressIconButton size="50px">
                <Icon name="libraryBooksFilled" color={azulTucar} size="30px" />
              </PressIconButton>
            ) : (
              <Icon name="libraryBooksFilled" color="#8E90A4" size="30px" />
            )
          }
        />

        <BottomNavigationAction
          sx={{ ...styleButtonNav, '&.Mui-selected': styleButtonNavSelected }}
          // onClick={handleChatButtonClick}
          onClick={() => window.Intercom('show')}
          icon={
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  display: unreadCount > 0 ? 'block' : 'none',
                  height: '10px',
                  width: '10px',
                  background: 'red',
                  borderRadius: '10px',
                  position: 'absolute',
                  top: '0px',
                  left: '0px',
                }}
              />
              <SupportAgentOutlinedIcon sx={{ fontSize: '30px', color: '#8E90A4' }} />
            </Box>
          }
        />
      </BottomNavigation>
    </Paper>
  );
}

export default NewNavBar;
