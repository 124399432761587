import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { getRefreshToken, setLoading, isLoading, logout } from '../../../graphql/store';
// import { useSalesforceContext } from '../../../contexts/SalesforceContext';
import POST_LOGOUT from '../../../graphql/mutations/logOut';
import SubmitButton from '../../SubmitButton';

const LogOutSubmit = () => {
  const [desactivateToken] = useMutation(POST_LOGOUT.mutation, POST_LOGOUT.policies);
  const [load, setLoad] = useState(false);
  const refreshToken = getRefreshToken();
  const navigate = useNavigate();
  // const { openSalesforceChat } = useSalesforceContext();

  useEffect(() => {
    if (load) {
      setLoading(false);
      logout();
      navigate('/signin', { replace: true });
      setLoad(false);
    } else if (!isLoading()) {
      setLoading(true);
    }
  }, [load, isLoading]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
      {/* <SubmitButton text="Soporte" handler={openSalesforceChat} /> */}
      <SubmitButton text="Soporte" handler={() => window.Intercom('show')} />
      <SubmitButton
        text="Salir"
        handler={() => {
          desactivateToken({ variables: { refreshToken } });
          logout();
          // eslint-disable-next-line no-restricted-globals
          navigate('/signin', { replace: true });
        }}
      />
    </Box>
  );
};

export default LogOutSubmit;
