import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Box, Typography, IconButton, Divider } from '@mui/material';
import { ExpandCircleDownOutlined } from '@mui/icons-material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import { useReactiveVar } from '@apollo/client';
import { getAuthStore, getLeadStore } from '../../../graphql/store';
// import { useSalesforceContext } from '../../../contexts/SalesforceContext';
import { azulTucar } from '../../../themes/variants/TucarColors';
import Emoji from '../../../components/extras/emojis';
import logotipo from '../../../../public/images/logotipo.svg';

import SubmitButton from '../../../components/SubmitButton';
import VideoModal from '../../../components/extras/VideoModal';
import LogOut from '../../../components/auth/LogOutButton';
import WaitingNotifications from './waitingNotifications';
import TermsAndConditionModal from '../../../components/info/termsAndCondition';

const EmptyDashboard = ({ notBooked, shared }) => {
  const [handlePageChange] = useOutletContext();
  const navigate = useNavigate();
  const { data } = useReactiveVar(getAuthStore);
  const [content, setContent] = useState();
  const { meeting } = useReactiveVar(getLeadStore);
  const [click, setClick] = useState(false);
  // const { openSalesforceChat } = useSalesforceContext();

  const [openVideo, setOpenVideo] = useState(false);
  const blueText = {
    display: 'inline',
    color: azulTucar,
    lineHeight: 1,
    fontSize: '0.87rem',
    fontFamily: 'Poppins-Regular',
  };
  const contentShared = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      }}
    >
      <Typography
        sx={{
          textAlign: 'center',
          fontFamily: 'Poppins-Regular',
          fontSize: '0.875rem',
        }}
      >
        ¡Ya estás casi listo para recibir un auto y ganar dinero manejando en apps!
      </Typography>
      <Typography
        sx={{
          textAlign: 'center',
          fontFamily: 'Poppins-Regular',
          fontSize: '0.875rem',
        }}
      >
        Estamos buscando un compañero de turno para ti. Nos contactaremos contigo cuando encontremos
        al indicado/a, para que pagues la garantía y coordinar la entrega del auto.
      </Typography>
      <Typography
        sx={{
          textAlign: 'center',
          fontFamily: 'Poppins-Regular',
          fontSize: '0.875rem',
        }}
      >
        Por mientras, te invitamos a ver este video con información importante del funcionamiento
        del Plan Premium.
      </Typography>

      <Box sx={{ width: '100%' }}>
        <SubmitButton
          backgroundColor="#95D31C"
          text="Ver video"
          handler={() => setOpenVideo(true)}
        />
      </Box>
    </Box>
  );
  const contentExclusive = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          paddingY: '20px',
        }}
      >
        <Box display="inline" sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography
            variant="span"
            sx={{ display: 'inline-block', lineHeight: 1, fontSize: '1rem' }}
            fontFamily="Poppins-Medium"
          >
            ¡Hola,
            <Typography
              sx={{
                display: 'inline-block',
                color: azulTucar,
                lineHeight: 1,
                fontSize: '1rem',
                paddingLeft: '0.4rem',
              }}
              fontFamily="Poppins-Medium"
            >
              {data.firstname}! <Emoji symbol="👋" label="hi" />
            </Typography>
          </Typography>
        </Box>

        <Box sx={{ boxShadow: '0px 3px 6px #0000000D', borderRadius: '10px' }}>
          <Box
            sx={{
              background: `linear-gradient(to right, ${azulTucar}, #006FEB)`,
              padding: '20px',
              borderRadius: '10px 10px 0px 0px',
            }}
          >
            <Typography
              sx={{
                textAlign: 'center',
                fontFamily: 'Poppins-Medium',
                fontSize: '1rem',
                color: '#FFFFFF',
              }}
            >
              {notBooked ? '¡Tus documentos han sido aprobados!' : '¡Tienes una charla programada!'}
            </Typography>
          </Box>

          <Box sx={{ padding: notBooked ? '20px' : '20px 20px 0px 20px' }}>
            <Box display="inline">
              {notBooked ? (
                <Typography
                  variant="span"
                  sx={{ display: 'inline-block', lineHeight: 1.4, fontSize: '0.87rem' }}
                  fontFamily="Poppins-Regular"
                >
                  ¡Quédate atento! Nuestro equipo se contactará contigo, para que pagues la garantía
                  y vengas por tu auto.
                </Typography>
              ) : (
                <Typography
                  variant="span"
                  sx={{ display: 'inline-block', lineHeight: 1.4, fontSize: '0.87rem' }}
                  fontFamily="Poppins-Regular"
                >
                  Agendaste para una charla presencial en nuestra sucursal, el día{' '}
                  <Typography sx={blueText}>{moment(meeting?.start).format('DD/MM/YY')}</Typography>{' '}
                  entre las{' '}
                  <Typography sx={blueText}>{moment(meeting?.start).format('LT')}</Typography> y las{' '}
                  <Typography sx={blueText}>{moment(meeting?.end).format('LT')} hrs</Typography>, en{' '}
                  <Typography sx={blueText}>Avenida Apoquindo 5830, Las Condes.</Typography>
                </Typography>
              )}
            </Box>
          </Box>

          {!notBooked && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '5px',
                paddingBottom: '20px',
              }}
            >
              <Typography sx={{ fontSize: '0.75rem', fontFamily: 'Poppins-Regular' }}>
                ¿No puedes retirar ese día?
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <IconButton
                  onClick={() => navigate('/app/payment', { replace: true })}
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    padding: '0px',
                    justifyContent: 'center',
                    borderRadius: '7px',
                  }}
                >
                  <Typography
                    sx={{ fontSize: '0.75rem', fontFamily: 'Poppins-Regular', color: azulTucar }}
                    noWrap
                  >
                    Contáctanos
                  </Typography>
                  <ExpandCircleDownOutlined
                    sx={{
                      transform: 'rotate(270deg)',
                      fontSize: '0.75rem',
                      color: azulTucar,
                      borderRadius: '50px',
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
  const contentNotification = <WaitingNotifications />;

  useEffect(() => {
    handlePageChange();
  }, []);

  useEffect(() => {
    if (click) {
      setContent(contentNotification);
    } else if (shared) {
      setContent(contentShared);
    } else {
      setContent(contentExclusive);
    }
  }, [click]);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'max-content max-content 1fr max-content',
        gap: '10px',
        flex: 1,
        height: '100vh',
        paddingInline: '20px',
        paddingBottom: '30px',
      }}
    >
      <TermsAndConditionModal />
      <VideoModal
        open={openVideo}
        handleClose={() => {
          setOpenVideo(false);
        }}
        title="¿Cómo funciona el plan eléctrico compartido?"
        srcVideo="https://www.youtube.com/embed/0ccDYwHXQKI"
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '4rem',
          padding: '10px 10px 0px 10px',
        }}
      >
        <img src={logotipo} alt="category" style={{ height: '32px' }} />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: click ? '#E9EFF8' : '#fff',
              padding: '10px',
              borderRadius: '30px',
            }}
            onClick={() => {
              setClick(!click);
            }}
          >
            <FeatherIcon icon="bell" stroke={azulTucar} />
          </Box>
          <LogOut noLinealOnboarding />
        </Box>
      </Box>
      <Divider />

      <Box
        sx={{
          overflowY: 'scroll',
          paddingInline: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {content}
      </Box>

      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'center',
            padding: '10px',
          }}
        >
          <SupportAgentIcon sx={{ color: azulTucar, fontSize: '40px' }} />
          <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '13px' }}>
            ¿Tienes problemas para completar el registro o dudas de nuestro servicio?
          </Typography>
        </Box>

        {/* <SubmitButton handler={openSalesforceChat} text="Contactar a un ejecutivo" /> */}
        <SubmitButton handler={() => window.Intercom('show')} text="Contactar a un ejecutivo" />
      </Box>
    </Box>
  );
};

EmptyDashboard.propTypes = {
  notBooked: PropTypes.bool,
  shared: PropTypes.bool,
};

EmptyDashboard.defaultProps = {
  notBooked: false,
  shared: false,
};

export default EmptyDashboard;
