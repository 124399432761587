/* eslint-disable no-console */
import urlBase64ToUint8Array from './urlBase64ToUnit8Array';

export const register = async () => {
  try {
    await navigator.serviceWorker.register('service-worker.js', {
      scope: '/',
    });
  } catch (error) {
    console.error('Service Worker registration failed:', error);
  }
};

export const send = async (uid) => {
  try {
    const registration = await navigator.serviceWorker.getRegistration();

    if (!registration) {
      throw new Error('Service Worker registration not found');
    }

    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(process.env.PUBLIC_VAP_ID_KEY),
    });

    await fetch(`${process.env.NOTIFICATION_SERVICE_URL}/driver/subscriptions`, {
      method: 'POST',
      body: JSON.stringify({ subscription, userId: uid }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.error('Failed to send push notification:', error);
  }
};
