import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { BottomNavigationAction, Paper, BottomNavigation, Divider, Box } from '@mui/material';
// import { Modal } from '@tucar/pit-stop';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { useReactiveVar } from '@apollo/client';
import { azulTucar } from '../../../themes/variants/TucarColors';
import { getDriverStore } from '../../../graphql/store';
// import { useSalesforceContext } from '../../../contexts/SalesforceContext';

function NavBar({ mobile: { mobileOpen, setMobileOpen } }) {
  const navigate = useNavigate();
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  // const [failedChatInit, setFailedChatInit] = useState(false);
  const [value, setValue] = useState(1);
  const { labelV2 } = useReactiveVar(getDriverStore);
  const [unreadCount, setUnreadCount] = useState(1);
  const styleButtonNav = { color: '#999999', minWidth: 'auto', paddingY: '0px' };
  const styleButtonNavSelected = { color: azulTucar, minWidth: 'auto', paddingY: '0px' };
  // const { openSalesforceChat, unreadCount } = useSalesforceContext();

  // const handleChatButtonClick = () => openSalesforceChat();
  const handleChatButtonClick = () => window.Intercom('show');

  window.Intercom('onUnreadCountChange', (currentUnreadCount) => {
    if (currentUnreadCount !== unreadCount) setUnreadCount(currentUnreadCount);
  });

  useEffect(() => {
    if (value === 1 && unreadCount === 0) {
      window.Intercom('showMessages');
      window.Intercom('hide');
    }
  }, [value]);

  return (
    <>
      {/* <Modal
        onClose={() => setFailedChatInit(false)}
        onConfirm={() => setFailedChatInit(false)}
        open={failedChatInit}
        title="Error al iniciar el Chat"
        variant="informative"
      >
        Hubo un error al iniciar el chat, por favor intenta abrirlo de nuevo más tarde.
      </Modal> */}
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: '2',
          height: '60px',
          backgroundColor: '#F0F0F3',
        }}
        elevation={5}
      >
        <Divider height="2px" />
        <BottomNavigation
          sx={{ height: '100%' }}
          value={value}
          onChange={(_, newValue) => setValue(newValue)}
        >
          <BottomNavigationAction
            sx={{ ...styleButtonNav, '&.Mui-selected': styleButtonNavSelected }}
            onClick={() =>
              navigate(labelV2 ? '/app/v2/profile' : '/app/profile', { replace: true })
            }
            icon={
              value === 0 ? (
                <AccountCircleIcon sx={{ fontSize: '30px' }} />
              ) : (
                <AccountCircleOutlinedIcon sx={{ fontSize: '30px' }} />
              )
            }
          />

          <BottomNavigationAction
            sx={{ ...styleButtonNav, '&.Mui-selected': styleButtonNavSelected }}
            onClick={() =>
              navigate(labelV2 ? '/app/v2/dashboard' : '/app/dashboard', { replace: true })
            }
            icon={
              value === 1 ? (
                <HomeIcon sx={{ fontSize: '30px' }} />
              ) : (
                <HomeOutlinedIcon sx={{ fontSize: '30px' }} />
              )
            }
          />

          <BottomNavigationAction
            sx={{ ...styleButtonNav, '&.Mui-selected': styleButtonNav }}
            onClick={handleDrawerToggle}
            icon={<MenuIcon sx={{ fontSize: '30px' }} />}
          />

          <BottomNavigationAction
            sx={{ ...styleButtonNav, '&.Mui-selected': styleButtonNavSelected }}
            onClick={() =>
              navigate(labelV2 ? '/app/v2/notifications' : '/app/notifications', { replace: true })
            }
            icon={
              value === 3 ? (
                <NotificationsIcon sx={{ fontSize: '30px' }} />
              ) : (
                <NotificationsNoneOutlinedIcon sx={{ fontSize: '30px' }} />
              )
            }
          />
          <BottomNavigationAction
            sx={{ ...styleButtonNav, '&.Mui-selected': styleButtonNav }}
            onClick={handleChatButtonClick}
            icon={
              <Box sx={{ position: 'relative' }}>
                <Box
                  sx={{
                    display: unreadCount > 0 ? 'block' : 'none',
                    height: '10px',
                    width: '10px',
                    background: 'red',
                    borderRadius: '10px',
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                  }}
                />
                <SupportAgentOutlinedIcon sx={{ fontSize: '30px', color: '#8E90A4' }} />
              </Box>
            }
          />
        </BottomNavigation>
      </Paper>
    </>
  );
}

NavBar.propTypes = {
  // eslint-disable-next-line react/require-default-props
  mobile: PropTypes.shape({
    mobileOpen: PropTypes.bool,
    setMobileOpen: PropTypes.func,
  }),
};

export default NavBar;
