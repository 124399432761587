import React from 'react';
import { Box } from '@mui/material';
import { Button, Icon, Typography } from '@tucar/pit-stop';
import { useReactiveVar } from '@apollo/client';
import onPointImg from '../../../../../../public/images/background/onPoint.png';
import { getDriverStore } from '../../../../../graphql/store';
// import { useSalesforceContext } from '../../../../../contexts/SalesforceContext';
import { azulTucar } from '../../../../../themes/variants/TucarColors';

const ReturnScreen = () => {
  const { status } = useReactiveVar(getDriverStore);
  // const { openSalesforceChat } = useSalesforceContext();
  if (status.status !== 'back to onboarding') return null;

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 3,
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '30px',
        backdropFilter: 'blur(2px)',
        background: 'linear-gradient(180deg, #9EC8FB 0%, #CCCDF8 100%)',
      }}
    >
      <Box
        // onClick={openSalesforceChat}
        onClick={() => window.Intercom('show')}
        sx={{
          position: 'absolute',
          paddingLeft: '10px',
          display: 'flex',
          alignItems: 'center',
          top: '50vh',
          right: '0px',
          width: '74px',
          height: '55px',
          borderRadius: '10px 0px 0px 10px',
          border: '4px solid #FFF',
          background: '#E3EDF7',
          boxShadow:
            '4px 4px 20px 0px rgba(111, 140, 176, 0.41), -6px -6px 20px 0px #FFF, 2px 2px 4px 0px rgba(114, 142, 171, 0.10)',
        }}
      >
        <Icon name="supportAgentOutlined" size="39px" color={azulTucar} />
      </Box>

      <Box
        sx={{
          paddingInline: '30px',
          alignSelf: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          align="center"
          color="#FFF"
          style={{ paddingInline: '60px', lineHeight: 1.2, fontSize: '28px', fontWeight: 600 }}
        >
          Revisa tus datos para continuar con el proceso
        </Typography>
        <img
          src={onPointImg}
          alt="block-img"
          height="10%"
          width="fit-content"
          style={{ alignSelf: 'center' }}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingInline: '40px',
            alignItems: 'center',
            paddingBottom: '40px',
          }}
        >
          <Typography
            align="center"
            color="#FFF"
            style={{ lineHeight: 1.2, fontSize: '16px', fontWeight: 600 }}
          >
            ¡Completa tu inscripción! Actualiza tus datos para seguir.
          </Typography>
        </Box>
        <Button style={{ width: 'max-content', alignSelf: 'center' }}>Completar datos</Button>
      </Box>
    </Box>
  );
};

export default ReturnScreen;
