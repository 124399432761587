import * as Sentry from '@sentry/react';
import { authStore } from './model';
import { resetDriver } from '../driver';
import { resetGlobal } from '../global';
import { resetDocs } from '../docs';
import { resetLead } from '../lead';
import { resetPlan } from '../plan';

const getExpirationDate = () => new Date().getTime() + 45 * 60 * 1000;

export const setExpirationDate = () => localStorage.setItem('expirationDate', getExpirationDate());

export const setRefreshToken = (refreshToken) => localStorage.setItem('refreshToken', refreshToken);

export const setAuthProvider = (authProvider) => localStorage.setItem('authProvider', authProvider);

export const setAuth = ({ refreshToken, accessToken, data }) => {
  setRefreshToken(refreshToken);
  setExpirationDate();
  setAuthProvider('Bearer');
  Sentry.setContext('Driver', { userId: data?.uid });
  authStore({ ...authStore(), accessToken, refreshToken, data });
};

export const logout = () => {
  window.Intercom('shutdown');
  const preLabel = localStorage.getItem('hiddeNewVersionFormModal');
  const preUpdate = localStorage.getItem('updateAtFormModal');
  const preLabelModal = localStorage.getItem('hiddeNewVersionModal');
  const preHiddeNews = localStorage.getItem('hiddeNews');
  localStorage.clear();
  localStorage.setItem('hiddeNewVersionModal', preLabelModal);
  localStorage.setItem('hiddeNewVersionFormModal', preLabel);
  localStorage.setItem('updateAtFormModal', preUpdate);
  localStorage.setItem('hiddeNews', preHiddeNews);

  authStore({
    ...authStore(),
    accessToken: false,
    refreshToken: false,
    data: {
      email: false,
      firstname: false,
      lastname: false,
      uid: false,
      userType: false,
      verified: false,
    },
  });
  resetDriver();
  resetGlobal();
  resetDocs();
  resetLead();
  resetPlan();
};
